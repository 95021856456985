import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

const BeneficiaryForm = () => {
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [formData, setFormData] = useState({
    // Basic Information
    englishFirstName: "",
    englishMiddleName: "",
    englishLastName: "",
    nepaliFirstName: "",
    nepaliMiddleName: "",
    nepaliLastName: "",
    phoneNumber: "",
    dob: "",
    gender: "",

    // Citizenship Details
    citizenshipNumber: "",
    issuanceDate: "",
    issuedBy: "",

    // Education
    literacyStatus: "",
    literacyLevel: "",

    //address
    address: {
      permanent: {
        province: "",
        block: "",
        district: "",
        municipality: "",
        ward: "",
        tole: "",
      },
      working: {
        province: "",
        block: "",
        district: "",
        municipality: "",
        ward: "",
        tole: "",
      },
    },

    //work Details
    agriculturalSector: "",
    yearsInField: 0,

    //landholdings
    landArea: {},
    landType: [],
    crops: [],
    cropArea: {},

    //income
    annualIncomeFromAgriculture: "",
    annualIncomeFromOther: "",
    timeSpentInAgriculture: "",

    //family details
    totalFamilyMembers: 1,
    familyMembersInAgriculture: 1,
    totalGendersInFamily: {
      male: 0,
      female: 0,
      other: 0,
    },
    familyMemberDetails: [],

    //livestock
    livestockOwned: [],
    livestockProduction: {
      goat: {},
      buffalo: {},
      poultry: {},
      fish: {},
      cow: {},
      OxBull: {},
      Pig: {},
    },

    //crop production
    cropsProduction: [],
    cropDetailsSpecific: {
      vegetables: [],
      fruits: [],
      seeds: [],
      mushroom: {},
      bees: {},
    },

    //agricultural equipment
    machinery: [],

    //financial info
    agriculturalLoans: {
      taken: "no",
      source: "",
      amount: "",
    },
    agriculturalInsurance: {
      enrolled: "no",
      insuredItems: "",
    },

    //land accessiblity
    //to deinfe

    //farming in other municipality
    farmingInOtherMunicipalities: {
      status: "",
      location: {
        province: "",
        district: "",
        municipality: "",
        ward: "",
      },
      area: {
        bigha: "",
        katha: "",
        dhur: "",
      },
      irrigationFacility: "",
      fieldInAgriculture: "",
      specificField: "",
      ownership: "",
      ownerName: "",
    },

    //business
    business: {
      registrant_no: "",
      registration_date: "",
      business_name: "",
      registered_office: "",
      pan_no: "",
      chairman_director: "",
      occupation_type: "",
      number_of_members: "",
      employment_creation: "",
    },
  });

  const [expandedSections, setExpandedSections] = useState({
    basicInfo: true,
    education: true,
    address: true,
    workDetails: true,
    landHoldings: true,
    familyDetails: true,
    income: true,
    livestock: true,
    cropProduction: true,
    agriculturalEquipment: true,
    financialInfo: true,
    // cropProduction: true,
    otherQuestions: true,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "received");
    // console.log(name, value);
    setFormData((prev) => {
      const keys = name.split(".");
      const updatedFormData = { ...prev };

      let current = updatedFormData;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          current[key] = current[key] || {};
          current = current[key];
        }
      });

      return updatedFormData;
    });
  };

  // const handleSelectChange = (e, section, name) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     [section]: {
  //       ...prev[section],
  //       [name]: e.target.value,
  //     },
  //   }));
  // };

  //3 ta level huda lai
  const handleNestedThreeInputChange = (
    parentKey,
    childKey,
    nestedChildKey,
    nestedKey,
    value
  ) => {
    console.log(
      parentKey,
      childKey,
      nestedChildKey,
      nestedKey,
      value,
      "received"
    );
    setFormData((prev) => ({
      ...prev,
      [parentKey]: {
        ...prev[parentKey],
        [childKey]: {
          ...(prev[parentKey]?.[childKey] || {}),
          [nestedChildKey]: {
            ...(prev[parentKey]?.[childKey]?.[nestedChildKey] || {}),
            [nestedKey]: value,
          },
        },
      },
    }));
  };

  //2 ta level huda lai (had to make seperate because ?[childKey] was not working)
  const handleNestedInputChange = (parentKey, childKey, nestedKey, value) => {
    console.log(parentKey, childKey, nestedKey, value, "received");
    setFormData((prev) => ({
      ...prev,
      [parentKey]: {
        ...prev[parentKey],
        [childKey]: {
          ...(prev[parentKey]?.[childKey] || {}),
          [nestedKey]: value,
        },
      },
    }));
  };

  const handleCheckboxChange = (e, section) => {
    const { value, checked } = e.target;
    console.log(value, checked, "received");

    setFormData((prevState) => {
      const updatedSection = prevState[section] ? [...prevState[section]] : [];

      if (checked) {
        if (!updatedSection.includes(value)) {
          updatedSection.push(value);
        }
      } else {
        const index = updatedSection.indexOf(value);
        if (index > -1) {
          updatedSection.splice(index, 1);
        }
      }
      return {
        ...prevState,
        [section]: updatedSection,
      };
    });
  };

  //nested checkboxes ko problem solve garna:
  const handleNestedCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    const [parentKey, childKey] = name.split(".");
    console.log(parentKey, childKey, value, checked, "received");
    setFormData((prev) => {
      const updatedFormData = { ...prev };

      if (!updatedFormData[parentKey]) {
        updatedFormData[parentKey] = {};
      }

      if (!updatedFormData[parentKey][childKey]) {
        updatedFormData[parentKey][childKey] = [];
      }

      if (checked) {
        if (!updatedFormData[parentKey][childKey].includes(value)) {
          updatedFormData[parentKey][childKey].push(value);
        }
      } else {
        updatedFormData[parentKey][childKey] = updatedFormData[parentKey][
          childKey
        ].filter((item) => item !== value);

        // Optionally remove the other variety input when unchecked
        if (value === "others") {
          delete updatedFormData[parentKey][`${childKey}OtherVariety`];
        }
      }

      return updatedFormData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Log the form data as a JSON object to the console
    console.log("Form Data:", JSON.stringify(formData, null, 2));
  };

  const SectionHeader = ({ title, section }) => (
    <div
      className="flex justify-between items-center bg-blue-100 p-3 cursor-pointer"
      onClick={() => toggleSection(section)}
    >
      <h2 className="text-lg font-semibold">{title}</h2>
      {expandedSections[section] ? <ChevronUp /> : <ChevronDown />}
    </div>
  );

  const renderBasicInfo = () => (
    <div className={`p-4 ${expandedSections.basicInfo ? "block" : "hidden"}`}>
      {/* Name (English) */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Name (English)</h3>
        <div className="grid md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">First Name</label>
            <input
              type="text"
              name="englishFirstName"
              value={formData.englishFirstName}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Middle Name</label>
            <input
              type="text"
              name="englishMiddleName"
              value={formData.englishMiddleName}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Last Name</label>
            <input
              type="text"
              name="englishLastName"
              value={formData.englishLastName}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
        </div>
      </div>

      {/* Name (Nepali) */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Name (Nepali)</h3>
        <div className="grid md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">First Name</label>
            <input
              type="text"
              name="nepaliFirstName"
              value={formData.nepaliFirstName}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Middle Name</label>
            <input
              type="text"
              name="nepaliMiddleName"
              value={formData.nepaliMiddleName}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Last Name</label>
            <input
              type="text"
              name="nepaliLastName"
              value={formData.nepaliLastName}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
        </div>
      </div>

      {/* Contact */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2 block">Contact</h3>
        <div className="grid md:grid-cols-3 gap-4">
          <div>
            <label className="lock text-sm font-medium">Phone Number</label>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
        </div>
      </div>
      <div className="mb-6 grid md:grid-cols-2">
        <div>
          <label className="text-lg font-semibold mb-2">Date of Birth</label>
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleInputChange}
            className="mt-1 block border rounded-md p-2"
          />
        </div>
        {/* Gender */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Gender</h3>
          <div className="flex gap-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="gender"
                value="Male"
                checked={formData.gender === "Male"}
                onChange={handleInputChange}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <span className="ml-2">Male</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="gender"
                value="Female"
                checked={formData.gender === "Female"}
                onChange={handleInputChange}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <span className="ml-2">Female</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="gender"
                value="Other"
                checked={formData.gender === "Other"}
                onChange={handleInputChange}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <span className="ml-2">Other</span>
            </label>
          </div>
        </div>
      </div>
      {/* Citizenship Details */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Citizenship Details</h3>
        <div className="grid md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">
              Citizenship Number
            </label>
            <input
              type="text"
              name="citizenshipNumber"
              value={formData.citizenshipNumber}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Issuance Date</label>
            <input
              type="date"
              name="issuanceDate"
              value={formData.issuanceDate}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Issued By</label>
            <input
              type="text"
              name="issuedBy"
              value={formData.issuedBy}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const renderEducation = () => (
    <div className={`p-4 ${expandedSections.education ? "block" : "hidden"}`}>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium">Literacy Status</label>
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="literacyStatus"
                value="Literate"
                checked={formData.literacyStatus === "Literate"}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Literate</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="literacyStatus"
                value="Illiterate"
                checked={formData.literacyStatus === "Illiterate"}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Illiterate</span>
            </label>
          </div>
        </div>
        {formData.literacyStatus === "Literate" && (
          <div>
            <label className="block text-sm font-medium">Literacy Level</label>
            <select
              name="literacyLevel"
              value={formData.literacyLevel}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            >
              <option value="">Select Level</option>
              <option value="Primary">Primary</option>
              <option value="Secondary">Secondary</option>
              <option value="Higher">Higher Education</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );

  //to copy in "same as permanent"
  const copyAddress = (from, to) => {
    const fields = [
      "Province",
      "Block",
      "District",
      "Municipality",
      "Ward",
      "Tole",
    ];
    setFormData((prev) => {
      const updated = { ...prev };
      fields.forEach((field) => {
        updated[`${to}${field}`] = prev[`${from}${field}`];
      });
      return updated;
    });
  };

  const renderAddress = () => (
    <div className={`p-4 ${expandedSections.address ? "block" : "hidden"}`}>
      {/* Permanent Address */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Permanent Address</h3>
        <div className="grid md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Province</label>
            <input
              type="text"
              name="address.permanent.province"
              value={formData.address.permanent.province}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Block</label>
            <input
              type="text"
              name="address.permanent.block"
              value={formData.address.permanent.block}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">District</label>
            <input
              type="text"
              name="address.permanent.district"
              value={formData.address.permanent.district}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Municipality</label>
            <input
              type="text"
              name="address.permanent.municipality"
              value={formData.address.permanent.municipality}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Ward</label>
            <input
              type="text"
              name="address.permanent.ward"
              value={formData.address.permanent.ward}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Tole</label>
            <input
              type="text"
              name="address.permanent.tole"
              value={formData.address.permanent.tole}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
        </div>
      </div>

      {/* Working Address */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Working Address</h3>
        <button
          type="button"
          onClick={() => copyAddress("perm", "work")}
          className="mb-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
        >
          Same as Permanent Address
        </button>
        <div className="grid md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium">Province</label>
            <input
              type="text"
              name="address.working.province"
              value={formData.address.working.province}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Block</label>
            <input
              type="text"
              name="address.working.block"
              value={formData.address.working.block}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">District</label>
            <input
              type="text"
              name="address.working.district"
              value={formData.address.working.district}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Municipality</label>
            <input
              type="text"
              name="address.working.municipality"
              value={formData.address.working.municipality}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Ward</label>
            <input
              type="text"
              name="address.working.ward"
              value={formData.address.working.ward}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Tole</label>
            <input
              type="text"
              name="address.working.tole"
              value={formData.address.working.tole}
              onChange={(e) => handleInputChange(e, "address.working", "tole")}
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const renderWorkDetails = () => (
    <div className={`p-4 ${expandedSections.workDetails ? "block" : "hidden"}`}>
      <fieldset className="mb-4">
        <legend className="text-lg font-semibold mb-2">
          Agricultural Sector
        </legend>
        <div className="grid md:grid-cols-3 gap-2">
          <label className="block text-sm">
            <input
              type="radio"
              name="agriculturalSector"
              value="Krishi/Bali"
              checked={formData.agriculturalSector === "Krishi/Bali"}
              onChange={handleInputChange}
              className="mr-2"
            />
            Krishi/Bali
          </label>
          <label className="block text-sm">
            <input
              type="radio"
              name="agriculturalSector"
              value="Pasupanchi"
              checked={formData.agriculturalSector === "Pasupanchi"}
              onChange={handleInputChange}
              className="mr-2"
            />
            Pasupanchi
          </label>
          <label className="block text-sm">
            <input
              type="radio"
              name="agriculturalSector"
              value="Mishrit"
              checked={formData.agriculturalSector === "Mishrit"}
              onChange={handleInputChange}
              className="mr-2"
            />
            Mishrit
          </label>
        </div>
      </fieldset>

      {formData.agriculturalSector && (
        <div className="mt-4">
          <label className="block text-sm font-medium">Years in Field</label>
          <input
            type="number"
            name="yearsInField"
            value={formData.yearsInField || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
            min="0"
          />
          {formData.yearsInField < 0 && (
            <p className="text-red-500 text-sm mt-1">
              Years must be 0 or more.
            </p>
          )}
        </div>
      )}
    </div>
  );

  const renderLandHoldings = () => {
    return (
      <div
        className={`p-4 ${expandedSections.landHoldings ? "block" : "hidden"}`}
      >
        {/* Land Area Inputs */}
        <h3 className="text-lg font-medium mb-4">Land Area</h3>
        <div className="grid md:grid-cols-3 gap-4">
          {["bigha", "katha", "dhur"].map((areaType) => (
            <div key={areaType}>
              <label className="block text-sm font-medium">
                Land Size (
                {areaType.charAt(0).toUpperCase() + areaType.slice(1)})
              </label>
              <input
                type="number"
                name={`landArea.${areaType}`}
                value={formData.landArea?.[areaType]?.number || ""}
                onChange={(e) =>
                  handleNestedInputChange(
                    "landArea",
                    areaType,
                    "number",
                    e.target.value
                  )
                }
                className="mt-1 block w-full border rounded-md p-2"
                min="0"
              />
            </div>
          ))}
        </div>

        {/* Land Type Inputs */}
        <fieldset className="mb-4">
          <legend className="text-lg font-medium">Land Type</legend>
          <div className="flex items-center space-x-4">
            {["khet", "bari"].map((landType) => (
              <label key={landType}>
                <input
                  type="checkbox"
                  value={landType}
                  checked={formData.landType?.includes(landType)}
                  onChange={(e) => handleCheckboxChange(e, "landType")}
                  className="mr-2"
                  aria-label={
                    landType.charAt(0).toUpperCase() + landType.slice(1)
                  }
                />
                {landType.charAt(0).toUpperCase() + landType.slice(1)}
              </label>
            ))}
          </div>
        </fieldset>

        {/* Conditional Area Inputs for Selected Crops */}
        {/* {formData.crops?.map((crop) => (
          <div key={crop} className="mb-4 border-t pt-4">
            <h3 className="text-sm font-medium capitalize">
              Area Growing {crop}
            </h3>
            <div className="grid md:grid-cols-3 gap-4 mt-2">
              {["bigha", "katha", "dhuri"].map((areaType) => (
                <div key={areaType}>
                  <label className="block text-sm font-medium">
                    {areaType.charAt(0).toUpperCase() + areaType.slice(1)}
                  </label>
                  <input
                    type="number"
                    name={`cropArea.${crop}.${areaType}`}
                    value={formData.cropArea?.[crop]?.[areaType] || ""}
                    onChange={(e) =>
                      handleNestedInputChange(
                        `cropArea.${crop}`,
                        areaType,
                        e.target.value
                      )
                    }
                    className="mt-1 block w-full border rounded-md p-2"
                    min="0"
                  />
                </div>
              ))}
            </div>
          </div>
        ))} */}
      </div>
    );
  };

  const renderIncomeAndResources = () => (
    <div className={`p-4 ${expandedSections.income ? "block" : "hidden"}`}>
      <div className="mb-4">
        <label className="block text-sm font-medium">
          Annual Income from Agriculture (NPR)
        </label>
        <input
          type="number"
          name="annualIncomeFromAgriculture"
          value={formData.annualIncomeFromAgriculture}
          onChange={handleInputChange}
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter annual income in NPR"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium">
          Annual Income from Other sector (NPR)
        </label>
        <input
          type="number"
          name="annualIncomeFromOther"
          value={formData.annualIncomeFromOther}
          onChange={handleInputChange}
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter annual income in NPR"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Time Spent in Agriculture/Year
        </label>
        <div className="grid grid-cols-2 gap-4 mt-1 w-full">
          <div className="flex items-center">
            <input
              type="radio"
              id="3months"
              name="timeSpentInAgriculture"
              value="3"
              checked={formData.timeSpentInAgriculture === "3"}
              onChange={handleInputChange}
              className="mr-2"
            />
            <label htmlFor="3months">3 Months</label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="6months"
              name="timeSpentInAgriculture"
              value="6"
              checked={formData.timeSpentInAgriculture === "6"}
              onChange={handleInputChange}
              className="mr-2"
            />
            <label htmlFor="6months">6 Months</label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="9months"
              name="timeSpentInAgriculture"
              value="9"
              checked={formData.timeSpentInAgriculture === "9"}
              onChange={handleInputChange}
              className="mr-2"
            />
            <label htmlFor="9months">9 Months</label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="12months"
              name="timeSpentInAgriculture"
              value="12"
              checked={formData.timeSpentInAgriculture === "12"}
              onChange={handleInputChange}
              className="mr-2"
            />
            <label htmlFor="12months">12 Months</label>
          </div>
        </div>
      </div>
    </div>
  );

  const renderFamilyDetails = () => (
    <div
      className={`p-4 ${expandedSections.familyDetails ? "block" : "hidden"}`}
    >
      <div className="grid md:grid-cols-2 gap-4">
        {/* Total Family Members */}
        <div>
          <label className="block text-sm font-medium">
            Total Family Members
          </label>
          <input
            type="number"
            min="1"
            name="totalFamilyMembers"
            value={formData.totalFamilyMembers}
            // max={10}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>

        {/* Family Members in Agriculture */}
        <div>
          <label className="block text-sm font-medium">
            Family Members in Agriculture
          </label>
          <input
            type="number"
            min="0"
            max={formData.totalFamilyMembers || ""}
            name="familyMembersInAgriculture"
            value={formData.familyMembersInAgriculture}
            onChange={handleInputChange}
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>

        {/* Total Genders */}
        <div className="col-span-2">
          <label className="block text-sm font-medium">Total Genders</label>
          <div className="flex flex-wrap gap-4 mt-1">
            {["Male", "Female", "Other (Optional)"].map((gender) => (
              <div key={gender} className="flex items-center">
                <input
                  type="number"
                  min="0"
                  name={`totalGendersInFamily.${gender.toLowerCase()}`}
                  value={
                    formData.totalGendersInFamily[gender.toLowerCase()] || null
                  }
                  onChange={handleInputChange}
                  className="w-16 border rounded-md p-2 mr-2"
                />
                <label className="text-sm">{gender}</label>
              </div>
            ))}
          </div>
        </div>

        {/* Additional Details (Conditionally Rendered) */}
        {formData.familyMembersInAgriculture > 1 && (
          <div className="mt-4 col-span-2">
            <h3 className="text-lg font-semibold mb-2">
              Details of Family Members in Agriculture
            </h3>
            <div className="grid gap-4">
              {Array.from({
                length: formData.familyMembersInAgriculture - 1,
              }).map((_, index) => (
                <div
                  key={index}
                  className="grid md:grid-cols-3 gap-6 border p-4 rounded-lg shadow-sm mb-4"
                >
                  <div className="col-span-3 mb-2">
                    <h4 className="text-lg font-semibold">
                      Family Member {index + 1}
                    </h4>
                  </div>

                  {/* Full Name */}
                  <div>
                    <label className="block text-sm font-medium">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name={`familyMemberDetails[${index}].fullName`}
                      value={
                        formData.familyMemberDetails?.[index]?.fullName || ""
                      }
                      onChange={handleInputChange}
                      className="mt-1 block w-full border rounded-md p-2"
                    />
                  </div>

                  {/* Relation with Farmer */}
                  <div>
                    <label className="block text-sm font-medium">
                      Relation with Farmer
                    </label>
                    <input
                      type="text"
                      name={`familyMemberDetails[${index}].relation`}
                      value={
                        formData.familyMemberDetails?.[index]?.relation || ""
                      }
                      onChange={handleInputChange}
                      className="mt-1 block w-full border rounded-md p-2"
                    />
                  </div>

                  {/* Age */}
                  <div>
                    <label className="block text-sm font-medium">Age</label>
                    <input
                      type="number"
                      min="0"
                      name={`familyMemberDetails[${index}].age`}
                      value={formData.familyMemberDetails?.[index]?.age || ""}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border rounded-md p-2"
                    />
                  </div>

                  {/* Gender */}
                  <div>
                    <label className="block text-sm font-medium">Gender</label>
                    <div className="flex gap-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id={`male-${index}`}
                          name={`familyMemberDetails[${index}].gender`}
                          value="Male"
                          checked={
                            formData.familyMemberDetails?.[index]?.gender ===
                            "Male"
                          }
                          onChange={handleInputChange}
                          className="mr-2"
                        />
                        <label htmlFor={`male-${index}`} className="text-sm">
                          Male
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id={`female-${index}`}
                          name={`familyMemberDetails[${index}].gender`}
                          value="Female"
                          checked={
                            formData.familyMemberDetails?.[index]?.gender ===
                            "Female"
                          }
                          onChange={handleInputChange}
                          className="mr-2"
                        />
                        <label htmlFor={`female-${index}`} className="text-sm">
                          Female
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id={`other-${index}`}
                          name={`familyMemberDetails[${index}].gender`}
                          value="Other"
                          checked={
                            formData.familyMemberDetails?.[index]?.gender ===
                            "Other"
                          }
                          onChange={handleInputChange}
                          className="mr-2"
                        />
                        <label htmlFor={`other-${index}`} className="text-sm">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Area of Agriculture */}
                  <div className="col-span-3">
                    <label className="block text-sm font-medium">
                      Area of Agriculture
                    </label>
                    <div className="flex flex-wrap gap-2">
                      {[
                        "उत्पादन क्षेत्र",
                        "प्रशोधन क्षेत्र",
                        "बजारिकरण",
                        "कृषि उद्यम",
                        "कृषि श्रमिक",
                      ].map((area) => (
                        <div key={area} className="flex items-center">
                          <input
                            type="checkbox"
                            name={`familyMemberDetails[${index}].areaOfAgriculture`}
                            value={area}
                            checked={
                              formData.familyMemberDetails?.[
                                index
                              ]?.areaOfAgriculture?.includes(area) || false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(
                                e,
                                `familyMemberDetails[${index}].areaOfAgriculture`
                              )
                            }
                            className="mr-2"
                          />
                          <label className="text-sm">{area}</label>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Citizenship No */}
                  <div>
                    <label className="block text-sm font-medium">
                      Citizenship No
                    </label>
                    <input
                      type="text"
                      name={`familyMemberDetails[${index}].citizenshipNo`}
                      value={
                        formData.familyMemberDetails?.[index]?.citizenshipNo ||
                        ""
                      }
                      onChange={handleInputChange}
                      className="mt-1 block w-full border rounded-md p-2"
                    />
                  </div>

                  {/* Phone Number */}
                  <div>
                    <label className="block text-sm font-medium">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name={`familyMemberDetails[${index}].phoneNumber`}
                      value={
                        formData.familyMemberDetails?.[index]?.phoneNumber || ""
                      }
                      onChange={handleInputChange}
                      className="mt-1 block w-full border rounded-md p-2"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const renderLivestockDetails = () => (
    <div className={`p-4 ${expandedSections.livestock ? "block" : "hidden"}`}>
      <div className="mb-4">
        <label className="block text-sm font-medium">
          Types of Livestock Owned
        </label>
        <div className="flex items-center space-x-4">
          {["goat", "buffalo", "poultry", "fish", "cow", "OxBull", "Pig"].map(
            (type) => (
              <label key={type}>
                <input
                  type="checkbox"
                  name="livestockOwned"
                  value={type}
                  checked={formData.livestockOwned.includes(type)}
                  onChange={(e) => handleCheckboxChange(e, "livestockOwned")}
                  className="mr-2"
                />
                {type.charAt(0).toUpperCase() + type.slice(1)}{" "}
                {/* Capitalize */}
              </label>
            )
          )}
        </div>
      </div>

      {formData.livestockOwned.length > 0 && (
        <div>
          {formData.livestockOwned.map((livestock) => (
            <div key={livestock} className="mb-4">
              <h3 className="text-sm font-medium">{livestock} Details</h3>
              <div className="grid grid-cols-2 gap-4">
                {livestock === "cow" || livestock === "buffalo" ? (
                  <>
                    <div>
                      <label className="block text-sm font-medium">
                        Number of {livestock}s
                      </label>
                      <input
                        type="number"
                        name={`livestockProduction[livestock].number`}
                        value={
                          formData.livestockProduction[livestock]?.number || ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            "number",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder={`Enter number of ${livestock}s`}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">
                        Months of Milk Production
                      </label>
                      <input
                        type="number"
                        name={`${livestock}_milkMonths`}
                        value={
                          formData.livestockProduction[livestock]?.milkMonths ||
                          ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            "milkMonths",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder="Enter number of months"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">
                        Milk per Day (liters)
                      </label>
                      <input
                        type="number"
                        name={`${livestock}_milkPerDay`}
                        value={
                          formData.livestockProduction[livestock]?.milkPerDay ||
                          ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            "milkPerDay",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder="Enter milk per day"
                      />
                    </div>
                  </>
                ) : livestock === "OxBull" ? (
                  <div>
                    <label className="block text-sm font-medium">
                      Number of Ox/Bulls
                    </label>
                    <input
                      type="number"
                      name={`${livestock}_number`}
                      value={
                        formData.livestockProduction[livestock]?.number || ""
                      }
                      onChange={(e) =>
                        handleNestedInputChange(
                          "livestockProduction",
                          livestock,
                          "number",
                          e.target.value
                        )
                      }
                      className="mt-1 block w-full border rounded-md p-2"
                      placeholder="Enter number of ox/bulls"
                    />
                  </div>
                ) : livestock === "goat" || livestock === "Pig" ? (
                  ["mau", "khasi", "boka", "patha/pathi"].map((category) => (
                    <div key={category}>
                      <label className="block text-sm font-medium">
                        Number of {category} ({livestock})
                      </label>
                      <input
                        type="number"
                        name={`${livestock}_${category}`}
                        value={
                          formData.livestockProduction[livestock]?.[category] ||
                          ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            category,
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder={`Enter number of ${category}`}
                      />
                    </div>
                  ))
                ) : livestock === "poultry" ? (
                  <>
                    <div>
                      <label className="block text-sm font-medium">
                        Number of Poultry
                      </label>
                      <input
                        type="number"
                        name={`${livestock}_number`}
                        value={
                          formData.livestockProduction[livestock]?.number || ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            "number",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder="Enter number of poultry"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">
                        Yearly Egg Production
                      </label>
                      <input
                        type="number"
                        name={`${livestock}_eggs`}
                        value={
                          formData.livestockProduction[livestock]?.eggs || ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            "eggs",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder="Enter yearly egg production"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">
                        Yearly Poultry Sale
                      </label>
                      <input
                        type="number"
                        name={`${livestock}_sale`}
                        value={
                          formData.livestockProduction[livestock]?.sale || ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            "sale",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder="Enter yearly poultry sale"
                      />
                    </div>
                  </>
                ) : livestock === "fish" ? (
                  <>
                    <div>
                      <label className="block text-sm font-medium">
                        Size of Pokhari (m²)
                      </label>
                      <input
                        type="number"
                        name={`${livestock}_size`}
                        value={
                          formData.livestockProduction[livestock]?.size || ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            "size",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder="Enter size of pokhari"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">
                        Number of Fish
                      </label>
                      <input
                        type="number"
                        name={`${livestock}_number`}
                        value={
                          formData.livestockProduction[livestock]?.number || ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            "number",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder="Enter number of fish"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">
                        Production (choose unit)
                      </label>
                      <select
                        name={`${livestock}_unit`}
                        value={
                          formData.livestockProduction[livestock]?.unit || ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            "unit",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                      >
                        <option value="kg">Kg</option>
                        <option value="number">Number</option>
                      </select>
                      <input
                        type="number"
                        name={`${livestock}_production`}
                        value={
                          formData.livestockProduction[livestock]?.production ||
                          ""
                        }
                        onChange={(e) =>
                          handleNestedInputChange(
                            "livestockProduction",
                            livestock,
                            "production",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder="Enter production"
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderCropProduction = () => (
    <div
      className={`p-4 ${expandedSections.cropProduction ? "block" : "hidden"}`}
    >
      {/* Main Crop Options */}
      <div className="mb-4">
        <label className="block text-sm font-medium">
          Types of Crops Grown
        </label>
        <div className="flex flex-wrap items-center space-x-4">
          {["Vegetables", "Fruits", "Seeds", "Mushroom", "Bees"].map((crop) => (
            <label key={crop} className="mr-4">
              <input
                type="checkbox"
                value={crop.toLowerCase()}
                checked={formData.cropsProduction?.includes(crop.toLowerCase())}
                onChange={(e) => handleCheckboxChange(e, "cropsProduction")}
                className="mr-2"
              />
              {crop}
            </label>
          ))}
        </div>
      </div>

      {/* Sub-options for Vegetables, Fruits, Seeds */}
      {["vegetables", "fruits", "seeds"].map(
        (cropType) =>
          formData.cropsProduction?.includes(cropType) && (
            <div key={cropType} className="mb-6 border-t pt-4">
              <h3 className="text-sm font-medium">
                {cropType.charAt(0).toUpperCase() + cropType.slice(1)} Varieties
              </h3>
              <div className="mb-4">
                <label className="block text-sm font-medium">
                  Select {cropType.charAt(0).toUpperCase() + cropType.slice(1)}
                </label>
                <div className="flex flex-wrap items-center space-x-4">
                  {["mango", "banana", "tomato", "papaya", "others"].map(
                    (item) => (
                      <label key={item}>
                        <input
                          type="checkbox"
                          value={item}
                          name={`cropDetails.${cropType}`}
                          checked={formData.cropDetails?.[cropType]?.includes(
                            item
                          )}
                          onChange={(e) => handleNestedCheckboxChange(e)}
                          className="mr-2"
                        />
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </label>
                    )
                  )}
                </div>

                {/* "Others" Input - Always visible when others is checked */}
                {formData.cropDetails?.[cropType]?.includes("others") && (
                  <input
                    type="text"
                    name={`cropDetails.${cropType}OtherVariety`}
                    value={
                      formData.cropDetails?.[`${cropType}OtherVariety`] || ""
                    }
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        cropDetails: {
                          ...prev.cropDetails,
                          [`${cropType}OtherVariety`]: e.target.value,
                        },
                      }))
                    }
                    placeholder={`Specify other ${cropType}`}
                    className="mt-2 block w-full border rounded-md p-2"
                  />
                )}
              </div>

              {formData.cropDetails?.[cropType]?.map((item) => (
                <div key={item} className="mb-4">
                  <h4 className="text-sm font-medium capitalize">
                    {item} Details
                  </h4>
                  <div className="grid grid-cols-2 gap-4 mt-2">
                    <div>
                      <label className="block text-sm font-medium">
                        Number of Trees
                      </label>
                      <input
                        type="number"
                        name={`cropDetailsSpecific.${cropType}.${item}.number`}
                        value={
                          formData.cropDetailsSpecific?.[cropType]?.[item]
                            ?.number || ""
                        }
                        placeholder={`Enter number of ${item}`}
                        onChange={(e) =>
                          handleNestedThreeInputChange(
                            "cropDetailsSpecific",
                            cropType,
                            item,
                            "number",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">
                        Production (kg/unit)
                      </label>
                      <input
                        type="number"
                        name={`cropDetailsSpecific.${cropType}.${item}.production`}
                        value={
                          formData.cropDetailsSpecific?.[cropType]?.[item]
                            ?.production || ""
                        }
                        onChange={(e) =>
                          handleNestedThreeInputChange(
                            "cropDetailsSpecific",
                            cropType,
                            item,
                            "production",
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full border rounded-md p-2"
                        placeholder={`Enter production for ${item} (kg/unit)`}
                      />
                    </div>

                    {/* Area Inputs (Bigha, Katha, Dhur) */}
                    {["bigha", "katha", "dhur"].map((areaType) => (
                      <div key={areaType}>
                        <label className="block text-sm font-medium">
                          Area (
                          {areaType.charAt(0).toUpperCase() + areaType.slice(1)}
                          )
                        </label>
                        <input
                          type="number"
                          name={`cropDetailsSpecific.${cropType}.${item}.area_${areaType}`}
                          value={
                            formData.cropDetailsSpecific?.[cropType]?.[item]?.[
                              `area_${areaType}`
                            ] || ""
                          }
                          onChange={(e) =>
                            handleNestedThreeInputChange(
                              "cropDetailsSpecific",
                              cropType,
                              item,
                              `area_${areaType}`,
                              e.target.value
                            )
                          }
                          className="mt-1 block w-full border rounded-md p-2"
                          placeholder={`Enter area for ${item} (${
                            areaType.charAt(0).toUpperCase() + areaType.slice(1)
                          })`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )
      )}

      {/* Sub-options for Mushroom and Bees */}
      {["mushroom", "bees"].map(
        (cropType) =>
          formData.cropsProduction?.includes(cropType) && (
            <div key={cropType} className="mb-6 border-t pt-4">
              <h3 className="text-sm font-medium">
                {cropType.charAt(0).toUpperCase() + cropType.slice(1)} Details
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="number"
                  name={`cropDetails.${cropType}.number`}
                  value={formData.cropDetails?.[cropType]?.number || ""}
                  placeholder={`Enter number of ${cropType} units`}
                  onChange={(e) =>
                    handleNestedThreeInputChange(
                      "cropDetails",
                      cropType,
                      cropType,
                      "number",
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                />
                <input
                  type="number"
                  name={`cropDetails.${cropType}.production`}
                  value={formData.cropDetails?.[cropType]?.production || ""}
                  placeholder={`Enter production (kg/unit)`}
                  onChange={(e) =>
                    handleNestedThreeInputChange(
                      "cropDetails",
                      cropType,
                      cropType,
                      "production",
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                />
              </div>
            </div>
          )
      )}
    </div>
  );

  const renderAgriculturalEquipment = () => (
    <div
      className={`p-4 ${
        expandedSections.agriculturalEquipment ? "block" : "hidden"
      }`}
    >
      <div className="mb-6">
        <h3 className="text-sm font-medium mb-2">Machinery:</h3>
        <div className="flex flex-col space-y-3">
          {["owned", "rented"].map((option) => (
            <label key={option} className="flex items-center">
              <input
                type="checkbox"
                value={option}
                checked={formData.machinery.includes(option)}
                onChange={(e) => handleCheckboxChange(e, "machinery")}
                className="mr-2"
              />
              {option.charAt(0).toUpperCase() + option.slice(1)} Machinery
            </label>
          ))}
        </div>
      </div>
    </div>
  );

  const renderFinancialInfo = () => (
    <div
      className={`p-4 ${expandedSections.financialInfo ? "block" : "hidden"}`}
    >
      <div className="mb-6">
        <h3 className="text-sm font-medium mb-2">Agricultural Loans:</h3>
        <div className="flex flex-col space-y-3">
          <div>
            <label className="block text-sm font-medium">Taken?</label>
            <div className="flex space-x-4">
              <label>
                <input
                  type="radio"
                  name="agriculturalLoansTaken"
                  value="yes"
                  checked={formData.agriculturalLoans.taken === "yes"}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalLoans", "taken")
                  }
                  className="mr-2"
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="agriculturalLoansTaken"
                  value="no"
                  checked={formData.agriculturalLoans.taken === "no"}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalLoans", "taken")
                  }
                  className="mr-2"
                />{" "}
                No
              </label>
            </div>
          </div>
  
          {formData.agriculturalLoans.taken === "yes" && (
            <div>
              <div>
                <label className="block text-sm font-medium">Source</label>
                <select
                  name="agriculturalLoansSource"
                  value={formData.agriculturalLoans.source}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalLoans", "source")
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                >
                  <option value="bank">Bank</option>
                  <option value="cooperative">Cooperative</option>
                  <option value="other">Other</option>
                </select>
              </div>
  
              <div className="mt-4">
                <label className="block text-sm font-medium">Amount</label>
                <input
                  type="number"
                  name="agriculturalLoansAmount"
                  value={formData.agriculturalLoans.amount}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalLoans", "amount")
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                  placeholder="Enter loan amount"
                />
              </div>
            </div>
          )}
        </div>
      </div>
  
      <div className="mb-6">
        <h3 className="text-sm font-medium mb-2">Agricultural Insurance:</h3>
        <div className="flex flex-col space-y-3">
          <div>
            <label className="block text-sm font-medium">Enrolled?</label>
            <div className="flex space-x-4">
              <label>
                <input
                  type="radio"
                  name="agriculturalInsuranceEnrolled"
                  value="yes"
                  checked={formData.agriculturalInsurance.enrolled === "yes"}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalInsurance", "enrolled")
                  }
                  className="mr-2"
                />{" "}
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="agriculturalInsuranceEnrolled"
                  value="no"
                  checked={formData.agriculturalInsurance.enrolled === "no"}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalInsurance", "enrolled")
                  }
                  className="mr-2"
                />{" "}
                No
              </label>
            </div>
          </div>
  
          {formData.agriculturalInsurance.enrolled === "yes" && (
            <div>
              <div>
                <label className="block text-sm font-medium">
                  Insured Items
                </label>
                <textarea
                  name="agriculturalInsuranceInsuredItems"
                  value={formData.agriculturalInsurance.insuredItems}
                  onChange={(e) =>
                    handleRadioboxChange(
                      e,
                      "agriculturalInsurance",
                      "insuredItems"
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                  placeholder="Enter details of insured crops or livestock"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  
  //specially for financial info sector; needed key to be passed
  const handleRadioboxChange = (e, section, key) => {
    const { value, checked } = e.target;
    console.log(value, checked, "received");
  
    setFormData((prevState) => {
      return {
        ...prevState,
        [section]: {
          ...prevState[section],
          [key]: checked ? value : "",
        },
      };
    });
  };
  
  
  const renderLandAccessibility = () => (
    <div className="p-4">
      <h3 className="font-semibold text-lg">
        Land Ownership and Lending Details
      </h3>

      {/* Land Ownership */}
      <div>
        <label className="block text-sm font-medium">Land Ownership</label>
        <label className="block">
          <input
            type="radio"
            name="landOwnership"
            value="self"
            checked={formData.landOwnership === "self"}
            onChange={(e) => handleInputChange(e)}
            className="mr-2"
          />
          Self
        </label>
        <label className="block">
          <input
            type="radio"
            name="landOwnership"
            value="lease"
            checked={formData.landOwnership === "lease"}
            onChange={(e) => handleInputChange(e)}
            className="mr-2"
          />
          Lease
        </label>

        {formData.landOwnership === "lease" && (
          <div className="mt-4">
            <label className="block text-sm font-medium">Owner's Name</label>
            <input
              type="text"
              name="landOwnerName"
              value={formData.landOwnerName || ""}
              onChange={(e) => handleInputChange(e)}
              className="mt-1 block w-full border rounded-md p-2"
              placeholder="Enter owner's name"
            />
          </div>
        )}
      </div>

      {/* Lending Land */}
      <div className="mt-4">
        <label className="block text-sm font-medium">
          Have you lent land to others?
        </label>
        <label className="block">
          <input
            type="radio"
            name="landLent"
            value="yes"
            checked={formData.landLent === "yes"}
            onChange={(e) => handleInputChange(e)}
            className="mr-2"
          />
          Yes
        </label>
        <label className="block">
          <input
            type="radio"
            name="landLent"
            value="no"
            checked={formData.landLent === "no"}
            onChange={(e) => handleInputChange(e)}
            className="mr-2"
          />
          No
        </label>

        {formData.landLent === "yes" && (
          <div>
            <div className="mt-4">
              {/* Lender's Name */}
              <label className="block text-sm font-medium">Lender's Name</label>
              <input
                type="text"
                name="lenderName"
                value={formData.lenderName || ""}
                onChange={(e) => handleInputChange(e)}
                className="mt-1 block w-full border rounded-md p-2"
                placeholder="Enter lender's name"
              />
            </div>

            {/* Land Type */}
            <div className="mt-4">
              <label className="block text-sm font-medium">Land Type</label>
              <select
                name="landType"
                value={formData.landType || ""}
                onChange={(e) => handleInputChange(e)}
                className="mt-1 block w-full border rounded-md p-2"
              >
                <option value="">Select Land Type</option>
                <option value="Khet">Khet</option>
                <option value="Bari">Bari</option>
              </select>
            </div>

            {/* Area in Biga, Katha, Dhur */}
            <div className="mt-4">
              <label className="block text-sm font-medium">
                Area (in Biga, Katha, Dhur)
              </label>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm">Biga</label>
                  <input
                    type="number"
                    name="areaBiga"
                    value={formData.areaBiga || ""}
                    onChange={(e) => handleInputChange(e)}
                    className="mt-1 block w-full border rounded-md p-2"
                    placeholder="Enter area in Biga"
                  />
                </div>
                <div>
                  <label className="block text-sm">Katha</label>
                  <input
                    type="number"
                    name="areaKatha"
                    value={formData.areaKatha || ""}
                    onChange={(e) => handleInputChange(e)}
                    className="mt-1 block w-full border rounded-md p-2"
                    placeholder="Enter area in Katha"
                  />
                </div>
                <div>
                  <label className="block text-sm">Dhur</label>
                  <input
                    type="number"
                    name="areaDhur"
                    value={formData.areaDhur || ""}
                    onChange={(e) => handleInputChange(e)}
                    className="mt-1 block w-full border rounded-md p-2"
                    placeholder="Enter area in Dhur"
                  />
                </div>
              </div>
            </div>

            {/* Irrigation Facility */}
            <div className="mt-4">
              <label className="block text-sm font-medium">
                Irrigation Facility
              </label>
              <div>
                <label className="block">
                  <input
                    type="radio"
                    name="irrigationFacility"
                    value="yes"
                    checked={formData.irrigationFacility === "yes"}
                    onChange={(e) => handleInputChange(e)}
                    className="mr-2"
                  />
                  Yes
                </label>
                <label className="block">
                  <input
                    type="radio"
                    name="irrigationFacility"
                    value="no"
                    checked={formData.irrigationFacility === "no"}
                    onChange={(e) => handleInputChange(e)}
                    className="mr-2"
                  />
                  No
                </label>
              </div>
            </div>

            {/* Irrigated Land Area */}
            {formData.irrigationFacility === "yes" && (
              <div className="mt-4">
                <label className="block text-sm font-medium">
                  Irrigated Land Area
                </label>
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm">Biga</label>
                    <input
                      type="number"
                      name="irrigatedLandAreaBiga"
                      value={formData.irrigatedLandAreaBiga || ""}
                      onChange={(e) => handleInputChange(e)}
                      className="mt-1 block w-full border rounded-md p-2"
                      placeholder="Enter area in Biga"
                    />
                  </div>
                  <div>
                    <label className="block text-sm">Katha</label>
                    <input
                      type="number"
                      name="irrigatedLandAreaKatha"
                      value={formData.irrigatedLandAreaKatha || ""}
                      onChange={(e) => handleInputChange(e)}
                      className="mt-1 block w-full border rounded-md p-2"
                      placeholder="Enter area in Katha"
                    />
                  </div>
                  <div>
                    <label className="block text-sm">Dhur</label>
                    <input
                      type="number"
                      name="irrigatedLandAreaDhur"
                      value={formData.irrigatedLandAreaDhur || ""}
                      onChange={(e) => handleInputChange(e)}
                      className="mt-1 block w-full border rounded-md p-2"
                      placeholder="Enter area in Dhur"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-sm font-medium">
                    Irrigation Source
                  </label>
                  <input
                    type="text"
                    name="irrigationSource"
                    value={formData.irrigationSource || ""}
                    onChange={(e) => handleInputChange(e)}
                    className="mt-1 block w-full border rounded-md p-2"
                    placeholder="Enter irrigation source"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );

  const renderFarmingInOtherMunicipalities = () => (
    <div className="p-4">
      <h3 className="font-semibold text-lg">Farming in Other Municipalities</h3>
      <label className="block">
        <input
          type="radio"
          name="farmingInOtherMunicipalities.status"
          value="yes"
          checked={formData.farmingInOtherMunicipalities.status === "yes"}
          onChange={(e) =>
            handleInputChange(e, "farmingInOtherMunicipalities", "status")
          }
          className="mr-2"
        />
        Yes
      </label>
      <label className="block">
        <input
          type="radio"
          name="farmingInOtherMunicipalities.status"
          value="no"
          checked={formData.farmingInOtherMunicipalities.status === "no"}
          onChange={(e) =>
            handleInputChange(e, "farmingInOtherMunicipalities", "status")
          }
          className="mr-2"
        />
        No
      </label>

      {formData.farmingInOtherMunicipalities.status === "yes" && (
        <div>
          {/* Details input */}
          {/* <div className="mt-4">
            <label className="block text-sm font-medium">Details</label>
            <input
              type="text"
              name="details"
              value={formData.farmingInOtherMunicipalities.details}
              onChange={(e) =>
                handleInputChange(e, "farmingInOtherMunicipalities", "details")
              }
              className="mt-1 block w-full border rounded-md p-2"
              placeholder="Enter details of farming in other municipalities"
            />
          </div> */}

          {/* Location inputs */}
          <div className="mt-4">
            <h4 className="text-sm font-semibold">Location</h4>
            <div className="grid gap-4 grid-cols-2">
              <div>
                <label className="block text-sm font-medium">Province</label>
                <input
                  type="text"
                  name="location.province"
                  value={
                    formData.farmingInOtherMunicipalities.location?.province ||
                    ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "farmingInOtherMunicipalities",
                      "location.province"
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">District</label>
                <input
                  type="text"
                  name="location.district"
                  value={
                    formData.farmingInOtherMunicipalities.location?.district ||
                    ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "farmingInOtherMunicipalities",
                      "location.district"
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">
                  Municipality
                </label>
                <input
                  type="text"
                  name="location.municipality"
                  value={
                    formData.farmingInOtherMunicipalities.location
                      ?.municipality || ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "farmingInOtherMunicipalities",
                      "location.municipality"
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Ward</label>
                <input
                  type="text"
                  name="location.ward"
                  value={
                    formData.farmingInOtherMunicipalities.location?.ward || ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "farmingInOtherMunicipalities",
                      "location.ward"
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                />
              </div>
            </div>
          </div>

          {/* Area inputs */}
          <div className="mt-4">
            <h4 className="text-sm font-semibold">Area</h4>
            <div className="grid gap-4 grid-cols-2">
              <div>
                <label className="block text-sm font-medium">Bigha</label>
                <input
                  type="number"
                  name="area.bigha"
                  value={
                    formData.farmingInOtherMunicipalities.area?.bigha || ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "farmingInOtherMunicipalities",
                      "area.bigha"
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                  min="0"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Katha</label>
                <input
                  type="number"
                  name="area.katha"
                  value={
                    formData.farmingInOtherMunicipalities.area?.katha || ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "farmingInOtherMunicipalities",
                      "area.katha"
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                  min="0"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Dhur</label>
                <input
                  type="number"
                  name="area.dhur"
                  value={formData.farmingInOtherMunicipalities.area?.dhur || ""}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "farmingInOtherMunicipalities",
                      "area.dhur"
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                  min="0"
                />
              </div>
            </div>
          </div>

          {/* Irrigation facility */}
          <div className="mt-4">
            <h4 className="text-sm font-semibold">Irrigation Facility</h4>
            <label className="block">
              <input
                type="radio"
                name="irrigationFacility"
                value="yes"
                checked={
                  formData.farmingInOtherMunicipalities.irrigationFacility ===
                  "yes"
                }
                onChange={(e) =>
                  handleInputChange(
                    e,
                    "farmingInOtherMunicipalities",
                    "irrigationFacility"
                  )
                }
                className="mr-2"
              />
              Yes
            </label>
            <label className="block">
              <input
                type="radio"
                name="irrigationFacility"
                value="no"
                checked={
                  formData.farmingInOtherMunicipalities.irrigationFacility ===
                  "no"
                }
                onChange={(e) =>
                  handleInputChange(
                    e,
                    "farmingInOtherMunicipalities",
                    "irrigationFacility"
                  )
                }
                className="mr-2"
              />
              No
            </label>
          </div>

          {/* Field in Agriculture */}
          <div className="mt-4">
            <h4 className="text-sm font-semibold">Field in Agriculture</h4>
            <label className="block">
              <input
                type="radio"
                name="fieldInAgriculture"
                value="Krishi/Bali"
                checked={
                  formData.farmingInOtherMunicipalities.fieldInAgriculture ===
                  "Krishi/Bali"
                }
                onChange={(e) =>
                  handleInputChange(
                    e,
                    "farmingInOtherMunicipalities",
                    "fieldInAgriculture"
                  )
                }
                className="mr-2"
              />
              Krishi/Bali
            </label>
            <label className="block">
              <input
                type="radio"
                name="fieldInAgriculture"
                value="Pasupanchi"
                checked={
                  formData.farmingInOtherMunicipalities.fieldInAgriculture ===
                  "Pasupanchi"
                }
                onChange={(e) =>
                  handleInputChange(
                    e,
                    "farmingInOtherMunicipalities",
                    "fieldInAgriculture"
                  )
                }
                className="mr-2"
              />
              Pasupanchi
            </label>
            <label className="block">
              <input
                type="radio"
                name="fieldInAgriculture"
                value="Mix"
                checked={
                  formData.farmingInOtherMunicipalities.fieldInAgriculture ===
                  "Mix"
                }
                onChange={(e) =>
                  handleInputChange(
                    e,
                    "farmingInOtherMunicipalities",
                    "fieldInAgriculture"
                  )
                }
                className="mr-2"
              />
              Mix
            </label>
          </div>

          {/* Specific Field in Agriculture */}
          <div className="mt-4">
            <label className="block text-sm font-medium">
              Specific Field in Agriculture
            </label>
            <input
              type="text"
              name="specificField"
              value={formData.farmingInOtherMunicipalities.specificField}
              onChange={(e) =>
                handleInputChange(
                  e,
                  "farmingInOtherMunicipalities",
                  "specificField"
                )
              }
              className="mt-1 block w-full border rounded-md p-2"
              placeholder="Enter specific field in agriculture"
            />
          </div>

          {/* Ownership */}
          <div className="mt-4">
            <h4 className="text-sm font-semibold">Ownership</h4>
            <label className="block">
              <input
                type="radio"
                name="ownership"
                value="self"
                checked={
                  formData.farmingInOtherMunicipalities.ownership === "self"
                }
                onChange={(e) =>
                  handleInputChange(
                    e,
                    "farmingInOtherMunicipalities",
                    "ownership"
                  )
                }
                className="mr-2"
              />
              Self
            </label>
            <label className="block">
              <input
                type="radio"
                name="ownership"
                value="lease"
                checked={
                  formData.farmingInOtherMunicipalities.ownership === "lease"
                }
                onChange={(e) =>
                  handleInputChange(
                    e,
                    "farmingInOtherMunicipalities",
                    "ownership"
                  )
                }
                className="mr-2"
              />
              Lease
            </label>

            {formData.farmingInOtherMunicipalities.ownership === "lease" && (
              <div className="mt-4">
                <label className="block text-sm font-medium">
                  Name of Owner
                </label>
                <input
                  type="text"
                  name="ownerName"
                  value={formData.farmingInOtherMunicipalities.ownerName}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "farmingInOtherMunicipalities",
                      "ownerName"
                    )
                  }
                  className="mt-1 block w-full border rounded-md p-2"
                  placeholder="Enter name of the land owner"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const renderBusinessDetails = () => (
    <div className="p-4">
      <h2 className="text-lg font-medium mb-4">
        Business/Firm/Enterprise Details
      </h2>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Business/Firm/Enterprise Registrant No.
        </label>
        <input
          type="text"
          name="registrant_no"
          value={formData.business?.registrant_no || ""}
          onChange={(e) =>
            handleNestedInputChange(
              "business",
              "registrant_no",
              e.target.name,
              e.target.value
            )
          }
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter Registrant Number"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Registration Date</label>
        <input
          type="date"
          name="registration_date"
          value={formData.business?.registration_date || ""}
          onChange={(e) =>
            handleNestedInputChange(
              "business",
              "registration_date",
              e.target.value
            )
          }
          className="mt-1 block w-full border rounded-md p-2"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Name of Business/Firm/Enterprise
        </label>
        <input
          type="text"
          name="business_name"
          value={formData.business?.business_name || ""}
          onChange={(e) =>
            handleNestedInputChange(
              "business",
              "business_name",
              e.target.name,
              e.target.value
            )
          }
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter Name of Business/Firm/Enterprise"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Registered Office</label>
        <input
          type="text"
          name="registered_office"
          value={formData.business?.registered_office || ""}
          onChange={(e) =>
            handleNestedInputChange(
              "business",
              "registered_office",
              e.target.value
            )
          }
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter Registered Office"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">PAN No.</label>
        <input
          type="text"
          name="pan_no"
          value={formData.business?.pan_no || ""}
          onChange={(e) =>
            handleNestedInputChange("business", "pan_no", e.target.value)
          }
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter PAN No."
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Name of Chairman/Director
        </label>
        <input
          type="text"
          name="chairman_director"
          value={formData.business?.chairman_director || ""}
          onChange={(e) =>
            handleNestedInputChange(
              "business",
              "chairman_director",
              e.target.value
            )
          }
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter Name of Chairman/Director"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Type of Occupation/Firm/Enterprise
        </label>
        <select
          name="occupation_type"
          value={formData.business?.occupation_type || ""}
          onChange={(e) =>
            handleNestedInputChange(
              "business",
              "occupation_type",
              e.target.value
            )
          }
          className="mt-1 block w-full border rounded-md p-2"
        >
          <option value="">Select Type</option>
          <option value="agriculture">Agriculture</option>
          <option value="livestock">Livestock</option>
          <option value="mixed">Mixed</option>
          {/* Add more options as needed */}
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Number of Members</label>
        <input
          type="number"
          name="number_of_members"
          value={formData.business?.number_of_members || ""}
          onChange={(e) =>
            handleNestedInputChange(
              "business",
              "number_of_members",
              e.target.value
            )
          }
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter Number of Members"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Number of Annual Employment Creation
        </label>
        <input
          type="number"
          name="employment_creation"
          value={formData.business?.employment_creation || ""}
          onChange={(e) =>
            handleNestedInputChange(
              "business",
              "employment_creation",
              e.target.value
            )
          }
          className="mt-1 block w-full border rounded-md p-2"
          placeholder="Enter Number of Employment Creation"
        />
      </div>
    </div>
  );

  return (
    <div className="container mx-auto p-6 bg-gray-50">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg">
        {currentPage === 1 && (
          <>
            <SectionHeader title="Basic Information" section="basicInfo" />
            {renderBasicInfo()}
          </>
        )}
        {currentPage === 2 && (
          <>
            <SectionHeader title="Education" section="education" />
            {renderEducation()}
            <SectionHeader title="Address" section="address" />
            {renderAddress()}
            <SectionHeader title="Work Details" section="workDetails" />
            {renderWorkDetails()}
            <SectionHeader title="Land Holdings" section="landHoldings" />
            {renderLandHoldings()}
            <SectionHeader title="Income and Resources" section="income" />
            {renderIncomeAndResources()}
          </>
        )}
        {currentPage === 3 && (
          <>
            <SectionHeader title="Family Details" section="familyDetails" />
            {renderFamilyDetails()}

            <SectionHeader title="Livestock Details" section="livestock" />
            {renderLivestockDetails()}
            <SectionHeader title="Crop Production" section="cropProduction" />
            {renderCropProduction()}
            <SectionHeader
              title="Agricultural Equipment"
              section="agriculturalEquipment"
            />
            {renderAgriculturalEquipment()}
          </>
        )}

        {currentPage === 4 && (
          <>
            <SectionHeader
              title="Financial Information"
              section="financialInfo"
            />
            {renderFinancialInfo()}
            <SectionHeader
              title="Land Accessibility"
              section="landAccessibility"
            />
            {renderLandAccessibility()}
            <SectionHeader
              title="Farming in Other Municipalities"
              section="farmingInOtherMunicipalities"
            />
            {renderFarmingInOtherMunicipalities()}
          </>
        )}
        {currentPage === 5 && (
          <>
            <SectionHeader title="Business Details" section="business" />
            {renderBusinessDetails()}
          </>
        )}

        {/* Pagination controls */}
        <div className="p-4 flex justify-between">
          <button
            type="button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition disabled:bg-gray-400"
          >
            Previous
          </button>
          <button
            type="button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === 5}
            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition disabled:bg-gray-400"
          >
            Next
          </button>
        </div>

        {/* Submit Button */}
        {currentPage === 5 && (
          <div className="p-4">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition"
            >
              Submit Survey
            </button>
          </div>
        )}
      </form>
    </div>
  );
};
export default BeneficiaryForm;
