import React from "react";
import { Menu, X } from "lucide-react";

const Header = ({ toggleSidebar }) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const handleToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
    toggleSidebar();
  };

  return (
    <div className="md:hidden flex items-center justify-between p-4 bg-white shadow-md">
      <h1 className="text-xl font-bold text-gray-800">MIMO System</h1>
      <button
        onClick={handleToggle}
        className="text-gray-600 focus:outline-none"
      >
        {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
      </button>
    </div>
  );
};

export default Header;
