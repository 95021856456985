import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-100 to-gray-200 p-6">
      {/* 404 Heading */}
      <h1 className="text-9xl font-extrabold text-gray-800 mb-4">404</h1>

      {/* Description */}
      <p className="text-xl text-gray-600 mb-8 text-center max-w-md">
        Sorry, the page you're looking for doesn't exist. It might have been
        moved or deleted.
      </p>

      {/* SVG Illustration */}
      <div className="mb-10">
        <svg
          className="h-48 w-48 text-blue-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M12 6v6l4 2m6-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>

      {/* Go Home Button */}
      <button
        onClick={handleGoHome}
        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-8 rounded-lg shadow-lg transition-transform duration-300 transform hover:scale-105"
      >
        Return Home
      </button>

      {/* Subtle Footer Text */}
      <p className="mt-6 text-gray-500 text-sm italic">
        Let's get you back on track!
      </p>
    </div>
  );
};

export default NotFoundPage;
