import React from "react";

const Navbar = () => {
  return (
    <nav className="bg-red-800 text-white py-4 shadow-md">
      <div className="container mx-auto flex flex-wrap justify-between items-center px-4">
        <div className="text-xl font-bold"></div>
        <ul className="flex space-x-6">
          <li>
            <a href="#home" className="hover:underline">
              Home
            </a>
          </li>
          <li>
            <a href="#about" className="hover:underline">
              About Us
            </a>
          </li>
          <li>
            <a href="#contact" className="hover:underline">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
