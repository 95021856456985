import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BeneficiaryList from "../components/BeneficiaryList";

const ProfileManagement = () => {
  const navigate = useNavigate();
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const dummyData = [
    { id: 1, name: "John Doe", phone: "1234567890" },
    { id: 2, name: "Jane Smith", phone: "9876543210" },
    { id: 3, name: "Alice Johnson", phone: "4561237890" },
    { id: 4, name: "Bob Brown", phone: "3216549870" },
    { id: 5, name: "Charlie White", phone: "7890123456" },
  ];

  const fetchBeneficiaries = async () => {
    setLoading(true);
    try {
      // Simulate API delay
      setTimeout(() => {
        let filteredData = dummyData;

        if (searchTerm) {
          if (/^\d+$/.test(searchTerm)) {
            filteredData = dummyData.filter((b) =>
              b.phone.includes(searchTerm)
            );
          } else {
            filteredData = dummyData.filter((b) =>
              b.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
          }
        }

        setBeneficiaries(filteredData);
        setError(null);
        setLoading(false);
      }, 500);
    } catch (err) {
      setError("Failed to fetch beneficiaries: " + err.message);
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchBeneficiaries();
  };

  const handleDeleteBeneficiary = (id) => {
    setBeneficiaries((prev) => prev.filter((b) => b.id !== id));
  };

  const handleEditBeneficiary = (beneficiary) => {
    navigate(`/beneficiaryform/${beneficiary.id}`, { state: { beneficiary } });
  };

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-12">
          Beneficiary Management
        </h1>

        {/* Search and Loading */}
        <div className="relative mb-6">
          <BeneficiaryList
            beneficiaries={beneficiaries}
            onEdit={handleEditBeneficiary}
            onDelete={handleDeleteBeneficiary}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleSearch={handleSearch}
          />

          {/* Display Loading Message Below the Search Area */}
          {loading && (
            <p className="text-center text-gray-600 text-lg animate-pulse">
              Searching...
            </p>
          )}

          {/* Display Error Message */}
          {error && <p className="text-red-500 text-center">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default ProfileManagement;
