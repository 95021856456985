import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import EmblemofNepal from "../asset/EmblemofNepal.png"; // Adjust path if necessary
import Flag from "../asset/Flag.gif";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";

const GovLogo = () => (
  <img
    src={EmblemofNepal}
    alt="Emblem of Nepal"
    className="w-32 md:w-48 h-28 md:h-44 object-container  mx-auto mb-4 md:mb-0 "
  />
);

const FlagOfNepal = () => (
  <div className="flex justify-center items-center my-4">
    <img
      src={Flag}
      alt="Flag of Nepal"
      className="w-16 md:w-28 object-container "
    />
  </div>
);

const App = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/login"); // Redirect to the login page
  };

  return (
    <div>
      {/* Header Section */}
      <div className="bg-white  shadow-2xl overflow-hidden ">
        <div className="p-8 bg-gradient-to-r from-blue-400 to-blue-600 text-white">
          <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-8">
            <GovLogo />
            <div className="text-center md:text-left flex-grow">
              <h2 className="text-3xl font-bold mb-3">
                Mahakali Irrigation Project
              </h2>
              <p className="text-lg opacity-80 mb-4">Management System</p>
              <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
                <span className="bg-white bg-opacity-20 px-4 py-2 rounded-full text-sm">
                  Kanchanpur
                </span>
                <span className="bg-white bg-opacity-20 px-4 py-2 rounded-full text-sm">
                  Fiscal Year 2080/81
                </span>
              </div>
            </div>
            <FlagOfNepal />
          </div>
        </div>
      </div>

      <Navbar />

      {/* Carousel */}
      <div className="container mx-auto my-8">
        <div className="relative overflow-hidden rounded-lg shadow-lg">
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            dynamicHeight={false}
            interval={5000}
            stopOnHover={true}
            className="rounded-lg"
            swipeable={true}
          >
            <div className="relative">
              <img
                src="https://via.placeholder.com/1200x600/3B82F6/FFFFFF?text=Welcome+to+Mahakali+Irrigation"
                alt="Slide 1"
                className="object-cover h-[400px] w-full"
              />
              <div className="absolute bottom-4 left-4 bg-white bg-opacity-70 p-3 rounded-lg shadow">
                <h3 className="text-lg font-semibold text-gray-800">
                  Streamline Operations
                </h3>
                <p className="text-sm text-gray-600">
                  Explore efficient project management.
                </p>
              </div>
            </div>
            <div className="relative">
              <img
                src="https://via.placeholder.com/1200x600/10B981/FFFFFF?text=Efficient+Management"
                alt="Slide 2"
                className="object-cover h-[400px] w-full"
              />
              <div className="absolute bottom-4 left-4 bg-white bg-opacity-70 p-3 rounded-lg shadow">
                <h3 className="text-lg font-semibold text-gray-800">
                  Monitor Progress
                </h3>
                <p className="text-sm text-gray-600">
                  Stay updated with real-time insights.
                </p>
              </div>
            </div>
            <div className="relative">
              <img
                src="https://via.placeholder.com/1200x600/EF4444/FFFFFF?text=Advanced+Tools"
                alt="Slide 3"
                className="object-cover h-[400px] w-full"
              />
              <div className="absolute bottom-4 left-4 bg-white bg-opacity-70 p-3 rounded-lg shadow">
                <h3 className="text-lg font-semibold text-gray-800">
                  Collaborate Seamlessly
                </h3>
                <p className="text-sm text-gray-600">
                  Improve teamwork and productivity.
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>

      {/* Body Section */}
      <div className="container mx-auto py-16 px-4">
        <h2 className="text-3xl font-bold text-center mb-6">
          Welcome to the Mahakali Irrigation Project
        </h2>
        <p className="text-lg text-gray-700 text-center">
          This system is designed to manage and streamline all processes related
          to the Mahakali Irrigation Project. Explore our features to learn
          more.
        </p>
      </div>

      {/* Redirect Button */}
      <div className="px-auto py-4">
        <button
          onClick={handleRedirect}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
        >
          Redirect to Login Page (For Development)
        </button>
      </div>

      {/* Footer */}
      <footer className="bg-gray-800 text-gray-200 py-8">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row justify-around items-start md:items-center gap-8">
            {/* Quick Links Section */}
            <div>
              <h4 className="text-lg font-semibold text-gray-300 mb-3">
                Quick Links
              </h4>
              <ul className="space-y-2">
                <li>
                  <a href="https://irrigation-beneficiary-management.pages.dev/" className="hover:text-gray-400 transition-colors">
                    Ministry of Energy, Water Resources and Irrigation
                  </a>
                </li>
                <li>
                  <a href="https://irrigation-beneficiary-management.pages.dev/" className="hover:text-gray-400 transition-colors">
                    Department of Water Resources and Irrigation
                  </a>
                </li>
                <li>
                  <a href="https://irrigation-beneficiary-management.pages.dev/" className="hover:text-gray-400 transition-colors">
                    Department of Hydrology and Meteorology
                  </a>
                </li>
                <li>
                  <a href="https://irrigation-beneficiary-management.pages.dev/" className="hover:text-gray-400 transition-colors">
                    Water Resource Research and Development Centre
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact Section */}
            <div>
              <h4 className="text-lg font-semibold text-gray-300 mb-3">
                Contact Us
              </h4>
              <p className="text-sm">Kanchanpur, Nepal</p>
              <p className="text-sm mt-2">
                <a
                  href="mailto:dwri.management@gmail.com"
                  className="hover:text-gray-400 transition-colors"
                >
                  [mail]@gmail.com
                </a>
              </p>
              <p className="text-sm">
                <a
                  href="mailto:dg_pasection@dwri.gov.np"
                  className="hover:text-gray-400 transition-colors"
                >
                  [mail2]@dwri.gov.np
                </a>
              </p>
              <p className="text-sm">
                <a
                  href="tel:+977-00000000"
                  className="hover:text-gray-400 transition-colors"
                >
                  +977-[00000000]
                </a>
              </p>
            </div>
          </div>

          {/* Copyright Section */}
        </div>
        <div className="mt-8 text-center">
          <p className="text-sm">
            &copy; 2024 [placeholder]. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default App;
