import React, { useState } from "react";
import { Edit, Trash2, Search } from "lucide-react";

const BeneficiaryList = ({
  beneficiaries,
  onEdit,
  onDelete,
  searchTerm,
  setSearchTerm,
  handleSearch, // New prop for search trigger
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedBeneficiaryId(id);
    setShowConfirmModal(true);
  };

  const confirmDelete = () => {
    onDelete(selectedBeneficiaryId);
    setShowConfirmModal(false);
    setSelectedBeneficiaryId(null);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
    setSelectedBeneficiaryId(null);
  };

  // Handle Enter key press to trigger search
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="bg-white rounded-2xl shadow-lg border border-gray-100 p-6 relative">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Beneficiary List</h2>
        <div className="relative flex items-center space-x-2">
          <input
            type="text"
            placeholder="Search by Name or Phone"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown} // Trigger search on Enter key press
            className="w-full pl-4 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
          />
          <button
            onClick={handleSearch}
            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-all"
          >
            <Search size={18} className="mr-2" />
            Search
          </button>
        </div>
      </div>

      {beneficiaries.length === 0 ? (
        <p className="text-gray-500">No beneficiaries found.</p>
      ) : (
        <ul className="space-y-4">
          {beneficiaries.map((beneficiary) => (
            <li
              key={beneficiary.id}
              className="flex justify-between items-center p-4 border-b last:border-b-0"
            >
              <div>
                <p className="text-lg font-semibold text-gray-800">
                  {beneficiary.name}
                </p>
                <p className="text-gray-600">{beneficiary.phone}</p>
              </div>
              <div className="flex space-x-4">
                <button
                  onClick={() => onEdit(beneficiary)}
                  className="text-blue-500 hover:text-blue-700 transition-colors"
                >
                  <Edit size={20} />
                </button>
                <button
                  onClick={() => handleDeleteClick(beneficiary.id)}
                  className="text-red-500 hover:text-red-700 transition-colors"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Custom Delete Confirmation Modal */}
      {showConfirmModal && (
        <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-20">
          <div className="bg-white rounded-lg p-6 shadow-lg w-80">
            <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
            <p className="text-gray-700 mb-6">
              Are you sure you want to delete this beneficiary?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={cancelDelete}
                className="px-4 py-2 text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BeneficiaryList;
